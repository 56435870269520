import {captureException} from '@sentry/react';
import * as s from 'underscore.string';
import {LocaleEntryNotFound} from './LocaleEntryNotFound';

export class Localization {
    private static EXCEPTIONS_BY_LOCALE = {
        en: [
            /* Articles */ 'a',
            'an',
            'the',
            /* Prepositions */ 'at',
            'by',
            'but',
            'for',
            'in',
            'of',
            'off',
            'per',
            'to',
            'up',
            'via',
            'on',
            /* Conjunctions */ 'as',
            'and',
            'but',
            'for',
            'how',
            'if',
            'nor',
            'now',
            'or',
            'so',
            'yet',
        ],
    };

    private static METHOD_BY_LOCALE: any = {
        en: Localization.convertToTitleCaseEnglish,
        fr: Localization.convertToTitleCaseFrench,
    };

    static convertToTitleCase(text: string, locale = 'en'): string {
        const method: (...args: any[]) => any = Localization.METHOD_BY_LOCALE[locale];
        if (method) {
            return method(text);
        }
        return text;
    }

    static convertToSentenceCase(str: string): string {
        // Regex lookbehind assertions are not supported by all browsers so instead of doing: str.split(/(?<=[.!?])\s/g)
        // We need to reverse the string and do a lookahead assertion then reverse it back in order to keep the delimiter

        const reverse = (initial: string) => initial.split('').reverse().join('');
        const sentences = reverse(str)
            .split(/\s(?=[.!?])/g)
            .map(reverse)
            .reverse();

        return sentences.map((sentence: string) => s.capitalize(sentence)).join(' ');
    }

    static reportKeyNotFound(key: string, locale = 'en') {
        try {
            captureException(
                new LocaleEntryNotFound(
                    `A string with the key "${key}" was not found while it was expected to for locale ${locale}.`,
                ),
            );
        } catch (e) {
            console.error(`Sentry could not capture the exception: ${e}`);
        }
    }

    private static convertToTitleCaseEnglish(text: string): string {
        // Based on https://rdwiki.corp.coveo.com/index.php/User_Interface_Text_Guidelines#Title_Case
        const tokens = text.split(/\s/g);
        const convertedTokens: string[] = [];
        for (let i = 0; i < tokens.length; i++) {
            const convertedToken = tokens[i];

            let hyphenatedTokens = convertedToken.split('-');

            if (hyphenatedTokens.length > 1) {
                hyphenatedTokens = hyphenatedTokens.map((word) => s.capitalize(word));
                convertedTokens.push(hyphenatedTokens.join('-'));
            } else {
                if (i === 0 || i === tokens.length - 1) {
                    convertedTokens.push(s.capitalize(convertedToken));
                } else if (Localization.EXCEPTIONS_BY_LOCALE['en'].includes(convertedToken)) {
                    convertedTokens.push(convertedToken);
                } else {
                    convertedTokens.push(s.capitalize(convertedToken));
                }
            }
        }
        return convertedTokens.join(' ');
    }

    private static convertToTitleCaseFrench(text: string): string {
        // Based on III http://french.about.com/library/writing/bl-capitalizationoftitles.htm
        const tokens = text.split(/\s/g);
        if (tokens.length >= 1) {
            tokens[0] = s.capitalize(tokens[0]);
        }
        return tokens.join(' ');
    }
}
