export const sanitizeOrganizationId = (orgId: string): string => {
    if (orgId == null) {
        return orgId;
    }

    return orgId.replace(/[^a-zA-Z0-9-]/g, '');
};

export const sanitizeHostedSearchPageId = (searchPageId: string): string => {
    if (searchPageId == null) {
        return searchPageId;
    }

    return searchPageId.replace(/[^a-zA-Z0-9\-]/g, '');
};

export const sanitizeClassicHostedSearchPageName = (searchPageName: string): string => {
    if (searchPageName == null) {
        return searchPageName;
    }

    return searchPageName.replace(/[^a-zA-Z0-9\-_]/g, '');
};

export const sanitizeRegion = (region: string): string => {
    if (region == null) {
        return region;
    }

    return region.replace(/[^a-zA-Z]/g, '');
};
