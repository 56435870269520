import Polyglot from 'node-polyglot';
import * as s from 'underscore.string';
import {Localization} from './LocalizationUtils';

export interface Dictionnary {
    locale: string;
    translations: any;
    fallbackLocale?: string;
}

export interface LocalesOptions<T extends string> {
    defaultLocale?: string;
    dictionnaries?: Dictionnary[];
    fallbackLocales?: Locales<T>;
}

export interface TranslationOptions extends Polyglot.InterpolationOptions {
    defaultTranslation?: string;
    titleCase?: boolean;
    reportMissingKey?: boolean;
}

interface Translations {
    [language: string]: Polyglot;
}

export class Locales<T extends string = string> {
    // Current locale used when calling the format method.
    locale: string;

    fallbackLocales: undefined | Locales<T>;

    // A map of language (en, fr, es, etc.) to corresponding Polyglot instance.
    private p_locales: Translations;

    constructor(options?: LocalesOptions<T>) {
        this.p_locales = {};

        if (options) {
            if (options.defaultLocale) {
                this.locale = options.defaultLocale;
            }
            if (options.dictionnaries) {
                this.setDictionnaries(options.dictionnaries);
            }
            this.fallbackLocales = options.fallbackLocales;
        }
    }

    setDictionnary(dictionnary: Dictionnary) {
        this.p_locales[dictionnary.locale] = new Polyglot({
            phrases: dictionnary.translations,
            onMissingKey: (key: T, options: Polyglot.InterpolationOptions, locale: string): string => {
                if (
                    dictionnary.fallbackLocale &&
                    dictionnary.fallbackLocale !== locale &&
                    this.p_locales[dictionnary.fallbackLocale]
                ) {
                    return this.p_format(key, dictionnary.fallbackLocale, options);
                } else {
                    return key;
                }
            },
            locale: dictionnary.locale,
        });

        if (this.locale === undefined) {
            this.locale = dictionnary.locale;
        }
    }

    setDictionnaries(dictionnaries: Dictionnary[]) {
        dictionnaries.forEach((dictionnary: Dictionnary) => this.setDictionnary(dictionnary));
    }

    format(key: T, options?: TranslationOptions, locale = this.locale): string {
        return Locales.p_handleTileCaseOption(this.p_format(key, locale, options), options, locale);
    }

    formatOrHumanize(key: T | string, options?: TranslationOptions, locale = this.locale): string {
        let translation = this.p_format(key as T, locale, options);

        if (translation === key) {
            const toHumanize =
                typeof options?.defaultTranslation?.valueOf() === 'string' ? options.defaultTranslation : key;
            translation = s.humanize(toHumanize);
        }
        return Locales.p_handleTileCaseOption(translation, options, locale);
    }

    formatOrDefault(key: T | string, options?: TranslationOptions, locale = this.locale): string {
        let translation = this.p_format(key as T, locale, options);

        if (translation === key) {
            translation = typeof options?.defaultTranslation?.valueOf() === 'string' ? options.defaultTranslation : key;
        }
        return Locales.p_handleTileCaseOption(translation, options, locale);
    }

    private p_format(key: T, locale: string, options?: Polyglot.InterpolationOptions, secondTime?: boolean): string {
        if (this.p_locales[locale] === undefined) {
            throw `The "${locale}" language is not defined.`;
        }

        let translation = options ? this.p_locales[locale].t(key, options) : this.p_locales[locale].t(key);

        if (!secondTime && Locales.p_isMissingKey(key, translation) && this.fallbackLocales) {
            translation = this.fallbackLocales.p_format(key, locale, options, true);
        }
        Locales.p_handleReportMissingKeyOption(key, translation, options, locale);

        return translation;
    }

    private static p_handleTileCaseOption(translation: string, options?: TranslationOptions, locale?: string): string {
        return options && options.titleCase ? Localization.convertToTitleCase(translation, locale) : translation;
    }

    private static p_isMissingKey(key: string, translation: string) {
        return translation === key;
    }

    private static p_handleReportMissingKeyOption(
        key: string,
        translation: string,
        options?: TranslationOptions,
        locale?: string,
    ) {
        if (Locales.p_isMissingKey(key, translation) && options?.reportMissingKey) {
            // Disabling missing key errors as they spam the Sentry channel a lot
            // https://coveord.atlassian.net/browse/UITOOL-468
            // Localization.reportKeyNotFound(key, locale);
        }
    }
}
