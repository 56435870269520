export class LocaleEntryNotFound extends Error {
    stack: string;

    constructor(message?: string) {
        super();

        this.name = 'LocaleEntryNotFound';

        if (message) {
            this.message = message;
        }

        this.stack = (new Error() as any).stack;
    }
}
